
@import url("../node_modules/bootstrap/dist/css/bootstrap.css");

body{
  background-color: #f7f8f9;
}

.form-select {
  color: #5c6873 !important;
  border-color: #e4e7ea !important;
  height: calc(1.5em + 0.75rem + 2px) !important;
}

/* CSS Modern Style Card */

/* 

  Style for Card 
  id="newCardSister

*/
#newCardSister{
  border: 0px solid #5a61694a !important;
  box-shadow: 
      0 2px 0 #5a61691c,
      0 4px 8px #5a61691f,
      0 10px 10px #5a61690f,
      0 7px 70px #5a61691a 
      !important;
  
}

#newCardSister > .bg-info {

  background-image: linear-gradient(#00a1d1,#35b5db,#81d3dd) !important; /* Mais Escuro */
  /* background-image: linear-gradient(#00a1d1,#63c2de,#7cd5df) !important; *//* Mais Claro */
}

#newCardSister > .bg-danger {
  background: linear-gradient(#d13e3e,#f86c6b,#ff8181) !important;
}

#newCardSister > .card-header {
  border: none;
  box-shadow: 0px 1px 3px #5a6169ce;
  font-weight: bold;
  color: white;
}

#newCardSister > .card-body{
  padding: 2% 1% !important;
}

/* Style for Modal info */
.modal-info .modal-header {

  background-image: linear-gradient(#00a1d1,#35b5db,#81d3dd) !important; /* Mais Escuro */
}
.modal-info .modal-content {
  border: 1px solid lightslategrey;
}


/* Style for Table */

.table{
  --bs-table-striped-bg: rgb(27 135 245 / 5%) !important;
  --bs-table-hover-bg: rgb(0 67 255 / 6%) !important;
}

#newTableSister > thead {
  background-color: #fdfdfd;
  border-top: 1px solid #efefef;
  box-shadow: 0 1px 0 #6c6c6c;
}

#newTableSister > tbody > tr{
  border-top: 1px solid #5a61691c !important;
  border-bottom: 1px solid #5a61691c !important;
}

